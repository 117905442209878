<template>
  <b-container class="bv-example-row mb-3" fluid>
    <b-row>
      <b-col cols="12">
        <b-card-actions
            ref="cari-ekstre-card"
            id="cari-ekstre"
            @refresh="refreshStop('cari-ekstre-card')"
        >
          <b-row class="mt-1 unPrint">
            <b-col lg="6" md="12" sm="12" cols="12" class="justify-content-start d-flex">
              <b-button
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  variant="secondary"
                  class="mr-50"
                  @click="$router.go(-1)"
              >
                <feather-icon
                    icon="ChevronLeftIcon"
                    class="mr-50"
                />
                <span class="align-middle">Geri Dön</span>
              </b-button>
              <b-button
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  variant="primary"
                  class="mr-50"
                  :style="{backgroundColor:$store.getters.COLORS_GET.bgPrimary+'!important', border:'none'}"
              >
                <font-awesome-icon icon="fa-solid fa-file-excel" class="mr-50"/>
                <span class="align-middle">Excel'e Aktar</span>
              </b-button>
              <b-button
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  variant="info"
                  @click="print"
                  :style="{backgroundColor:$store.getters.COLORS_GET.bgInfo+'!important', border:'none'}"
              >
                <font-awesome-icon icon="fa-solid fa-print" class="mr-50"/>
                <span class="align-middle">Yazdır</span>
              </b-button>
            </b-col>
            <b-col lg="6" md="12" sm="12" cols="12" class="justify-content-end d-flex" :class="width < 992 ? 'mt-1':''">
              <b-input-group class="float-right justify-content-end">
                <b-input-group-prepend>
                  <flat-pickr v-model="start" class="form-control"/>
                </b-input-group-prepend>
                <b-button
                    variant="outline-secondary"
                    disabled
                    class="date-border-color"
                >
                  <feather-icon icon="CalendarIcon"/>
                </b-button>
                <b-input-group-append>
                  <flat-pickr v-model="end" class="form-control"/>
                </b-input-group-append>
              </b-input-group>
            </b-col>
          </b-row>
        </b-card-actions>
        <b-card-actions
            ref="cari-ekstre-card"
            id="cari-ekstre"
            @refresh="refreshStop('cari-ekstre-card')"
        >
          <b-row class="mt-1">
            <b-col cols="12" class="justify-content-between d-flex mt-50">
              <h3 class="d-inline-block">Cari Ekstre</h3>
              <h3 class="d-inline-block firma-unvan">{{ cariBilgi.firmaUnvan }}</h3>
              <h4 class="d-inline-block">
                {{ currentDate }}
              </h4>
            </b-col>
          </b-row>
          <hr/>
          <b-row class="mt-1">
            <b-col cols="6" class="justify-content-start d-block mt-50">
              <h5 class="text-muted">
                {{ cariBilgi.adi }} {{ cariBilgi.soyAdi }}
              </h5>
              <h5 class="text-muted">
                {{ cariBilgi.adres }}
              </h5>
              <h5 class="text-muted">
                {{ cariBilgi.il }} / {{ cariBilgi.ilce }}
              </h5>
            </b-col>
            <b-col cols="6" class="justify-content-end d-gr float-right mt-50 d-grid">
              <h5 class="text-muted text-right">
                Telefon : {{ cariBilgi.telefon }}
              </h5>
              <h5 class="text-muted text-right">
                Vergi Dairesi : {{ cariBilgi.vergiDairesi }}
              </h5>
              <h5 class="text-muted text-right">
                Vergi No : {{ cariBilgi.vergiNumarasi }}
              </h5>
            </b-col>
          </b-row>
          <hr/>
          <!--          <div class="d-flex justify-content-between flex-wrap mt-50">-->
          <!--            <b-form-group class="mr-1 mb-md-0 unPrint" size="sm">-->
          <!--              <b-input-group-->
          <!--                  size="sm"-->
          <!--                  prepend="Gösterilecek kayıt sayısı"-->
          <!--                  class="unPrint"-->
          <!--              >-->
          <!--                <b-form-select-->
          <!--                    id="per-page-select"-->
          <!--                    v-model="perPage"-->
          <!--                    :options="perPageOptions"-->
          <!--                    @change="PerPageChange($event)"-->
          <!--                    size="sm"-->
          <!--                >-->
          <!--                </b-form-select>-->
          <!--              </b-input-group>-->
          <!--            </b-form-group>-->
          <!--            <b-input-group size="sm" class="search-bar">-->
          <!--              <b-input-group-prepend>-->
          <!--                <b-button-->
          <!--                    variant="outline-primary"-->
          <!--                    size="sm"-->
          <!--                    disabled-->
          <!--                    class="search-input mb-0"-->
          <!--                >-->
          <!--                  Ara-->
          <!--                </b-button>-->
          <!--              </b-input-group-prepend>-->
          <!--              <b-form-input id="filterInput" v-model="filter" autocomplete="off" type="search"/>-->
          <!--            </b-input-group>-->
          <!--          </div>-->
          <!--          <b-table-->
          <!--              striped-->
          <!--              hover-->
          <!--              responsive-->
          <!--              class="position-relative cari-group-table mt-1 tahsilat-ve-odeme"-->
          <!--              :per-page="perPage"-->
          <!--              :items="cariEkstre"-->
          <!--              :fields="Fields"-->
          <!--              :sort-by.sync="sortBy"-->
          <!--              :sort-desc.sync="sortDesc"-->
          <!--              :sort-direction="sortDirection"-->
          <!--              :filter-included-fields="filterOn"-->
          <!--              @filtered="onFiltered"-->
          <!--          >-->
          <!--            <template #cell(islemTuru)="data">-->
          <!--              <b-badge-->
          <!--                  :variant="data.item.islemTuru == 'Borç Girişi' ? 'danger':'success' " id="firma-unvan-badge"-->
          <!--                  class="align-top mr-50"-->
          <!--              >-->
          <!--                <font-awesome-icon icon="fa-solid fa-hashtag"/>-->
          <!--                {{ data.item.islemTuru }}-->
          <!--              </b-badge>-->
          <!--            </template>-->
          <!--            <template #cell(islemNotu)="data">-->
          <!--              <font-awesome-icon v-if="data.item.islemNotu" icon="fa-solid fa-message"/>-->
          <!--              <strong class="ml-50">-->
          <!--                {{ data.item.islemNotu }}-->
          <!--              </strong>-->
          <!--            </template>-->
          <!--            <template #cell(kayitTarih)="data">-->
          <!--              <font-awesome-icon icon="fa-solid fa-calendar-days"/>-->
          <!--              <p class="d-inline-block ml-50">-->
          <!--                {{ data.item.kayitTarih.slice(0, 16) }}-->
          <!--              </p>-->
          <!--            </template>-->
          <!--            <template #cell(tutar)="data">-->
          <!--              <strong class="ml-50">-->
          <!--                {{ data.item.tutar }}-->
          <!--              </strong>-->
          <!--              <font-awesome-icon v-if="data.item.tutar" icon="fa-solid fa-turkish-lira-sign"/>-->
          <!--            </template>-->
          <!--          </b-table>-->
          <!--          <b-row class="d-flex align-items-center mt-1">-->
          <!--            <b-col cols="6">-->
          <!--              <p class="text-muted">Toplam {{ mevcutKayit }} Kayıt bulundu</p>-->
          <!--            </b-col>-->
          <!--            <b-col cols="6">-->
          <!--              <b-pagination-->
          <!--                  v-model="currentPage"-->
          <!--                  @change="pageChange($event)"-->
          <!--                  :total-rows="toplamKayit"-->
          <!--                  :per-page="perPage"-->
          <!--                  first-number-->
          <!--                  last-number-->
          <!--                  prev-class="prev-item"-->
          <!--                  next-class="next-item"-->
          <!--                  class="mb-0 float-right"-->
          <!--              />-->
          <!--            </b-col>-->
          <!--          </b-row>-->
          <table class="table mt-2 table-hover table-striped" :class="width < 760?'table-responsive':''">
            <thead>
            <tr>
              <th :style="{'backgroundColor': $store.getters.COLORS_GET.tableTitle +'!important','color':'white!important'}"
                  scope="col"
              >İşlem
                Türü
              </th>
              <th :style="{'backgroundColor': $store.getters.COLORS_GET.tableTitle +'!important','color':'white!important'}"
                  scope="col"
              >Açıklama
              </th>
              <th :style="{'backgroundColor': $store.getters.COLORS_GET.tableTitle +'!important','color':'white!important'}"
                  scope="col"
              >İşlem Tarihi
              </th>
              <th :style="{'backgroundColor': $store.getters.COLORS_GET.tableTitle +'!important','color':'white!important'}"
                  scope="col"
              >Borç
              </th>
              <th :style="{'backgroundColor': $store.getters.COLORS_GET.tableTitle +'!important','color':'white!important'}"
                  scope="col"
              >Alacak
              </th>
              <th :style="{'backgroundColor': $store.getters.COLORS_GET.tableTitle +'!important','color':'white!important'}"
                  scope="col"
              >Bakiye
              </th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="(item,i) in cariEkstre " :key="i">
              <td><strong>{{ item.islemTuru }}</strong></td>
              <td>{{ item.islemNotu }}</td>
              <td>{{ item.kayitTarih.slice(0, 16) }}</td>
              <td><p v-if="item.borc">{{ item.borc }} ₺</p></td>
              <td><p v-if="item.alacak">{{ item.alacak }} ₺</p></td>
              <td>
                <strong>{{ item.bakiye }} ₺</strong>
                <p class="p-0 m-0">
                  <span class="text-success" v-if="item.bakiye > 0">Tahsil Edilecek</span>
                  <span class="text-danger" v-else-if="item.bakiye < 0">Ödenecek</span>
                  <span class="text-secondary" v-else>Bakiye Yok</span>
                </p>
              </td>
            </tr>
            </tbody>
          </table>
          <hr/>
          <h3 class="text-right mt-1">
            Bakiye : {{
              Number(cariBilgi.bakiye ? cariBilgi.bakiye : 0)
                  .toFixed(2)
                  .replace(/\d(?=(\d{3})+\.)/g, '$&,')
            }}
            <font-awesome-icon icon="fa-solid fa-turkish-lira-sign"/>
          </h3>
          <small class="d-flex text-success text-right justify-content-end" v-if="cariBilgi.bakiye > 0">
            Tahsil Edilecek
          </small>
          <small v-else-if="cariBilgi.bakiye < 0" class="d-flex text-danger text-right justify-content-end">
            Ödenecek
          </small>
          <small v-else class="d-flex text-secondary text-right justify-content-end">
            Bakiye Yok
          </small>
        </b-card-actions>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import {
  BInputGroupPrepend,
  BCardHeader,
  BCardBody,
  BFormSelectOption,
  BListGroup,
  BListGroupItem,
  BMedia,
  BMediaAside,
  BMediaBody,
  BFormTextarea,
  BRow,
  BCol,
  BContainer,
  BTable,
  BBadge,
  BForm,
  BButton,
  BCard,
  BBreadcrumb,
  BButtonGroup,
  BFormInput,
  BInputGroup,
  BSpinner,
  BAvatar,
  BFormGroup,
  BFormSelect,
  BPagination,
  BInputGroupAppend,
  BFormCheckboxGroup,
  BFormCheckbox,
  BModal,
  VBModal,
} from 'bootstrap-vue'
import _ from 'lodash'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import AppTimeline from '@core/components/app-timeline/AppTimeline.vue'
import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem.vue'
import 'vue-search-select/dist/VueSearchSelect.css'
import { ModelListSelect } from 'vue-search-select/dist/VueSearchSelect.common'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import store from '@/store'
import BCardActions from '@core/components/b-card-actions/BCardActions.vue'
import flatPickr from 'vue-flatpickr-component'

const Turkish = require('flatpickr/dist/l10n/tr.js').default.tr
flatpickr.localize(Turkish)
flatpickr(flatPickr)
import { useWindowSize } from '@vueuse/core'

export default {
  setup() {
    const {
      width,
    } = useWindowSize()
    return {
      width,
    }
  },
  components: {
    flatPickr,
    ModelListSelect,
    BInputGroupPrepend,
    BCardActions,
    BCardHeader,
    BCardBody,
    BFormSelectOption,
    AppTimeline,
    AppTimelineItem,
    BFormTextarea,
    vSelect,
    BMedia,
    BMediaAside,
    BMediaBody,
    BListGroup,
    BListGroupItem,
    BRow,
    BCol,
    BContainer,
    BTable,
    BBadge,
    BForm,
    BButton,
    BCard,
    BBreadcrumb,
    BButtonGroup,
    BFormInput,
    BInputGroup,
    BSpinner,
    BAvatar,
    BFormGroup,
    BFormSelect,
    BPagination,
    BInputGroupAppend,
    BFormCheckboxGroup,
    BFormCheckbox,
    BModal,
  },
  data() {
    return {
      start: '',
      end: '',
      perPageOptions: [5, 10, 20, 30, 50, 100, 150, 200, 500],
      totalRows: 1,
      filter: null,
      filterOn: [],
      perPage: 20,
      windowWidth: 0,
      windowHeight: 0,
      id: this.$route.params.id,
      sortBy: '',
      Fields: [
        {
          key: 'islemTuru',
          label: 'İşlem Türü',
          sortable: true,
          thStyle: {
            backgroundColor: store.getters.COLORS_GET.tableTitle || '#3c6e71',
            color: 'white',
            border: 'none',
          },
        },
        {
          key: 'islemNotu',
          label: 'Açıklama',
          sortable: true,
          thStyle: {
            backgroundColor: store.getters.COLORS_GET.tableTitle || '#3c6e71',
            color: 'white',
            border: 'none',
          },
        },
        {
          key: 'kayitTarih',
          label: 'İşlem Tarihi',
          sortable: true,
          thStyle: {
            backgroundColor: store.getters.COLORS_GET.tableTitle || '#3c6e71',
            color: 'white',
            border: 'none',
          },
        },
        {
          key: 'borc',
          label: 'Borç',
          sortable: true,
          thStyle: {
            backgroundColor: store.getters.COLORS_GET.tableTitle || '#3c6e71',
            color: 'white',
            border: 'none',
          },
        },
        {
          key: 'alacak',
          label: 'Meblağ',
          sortable: true,
          thStyle: {
            backgroundColor: store.getters.COLORS_GET.tableTitle || '#3c6e71',
            color: 'white',
            border: 'none',
          },
        },
        {
          key: 'bakiye',
          label: 'Bakiye',
          sortable: true,
          thStyle: {
            backgroundColor: store.getters.COLORS_GET.tableTitle || '#3c6e71',
            color: 'white',
            border: 'none',
          },
        },
      ],
      sortDirection: 'asc',
      sortDesc: false,
      currentPage: 1,
      toplamKayit: 0,
      mevcutKayit: 0,
    }
  },
  computed: {
    currentDate() {
      if (this.start && this.end) {
        const firstGun = this.start.slice(8, 10)
        const firstAy = this.start.slice(5, 7)
        const firstYil = this.start.slice(0, 4)
        const secondGun = this.end.slice(8, 10)
        const secondAy = this.end.slice(5, 7)
        const secondYil = this.end.slice(0, 4)
        return `${firstGun} ${store.getters.TURKISH_MONTH(firstAy)} ${firstYil}  /  ${secondGun} ${store.getters.TURKISH_MONTH(secondAy)} ${secondYil}  `
      }
      const today = new Date()
      const dd = String(today.getDate())
          .padStart(2, '0')
      const mm = String(today.getMonth() + 1)
          .padStart(2, '0')
      const yyyy = today.getFullYear()
      return `${dd} ${store.getters.TURKISH_MONTH(mm)} ${yyyy}`
    },
    sortOptions() {
      // Create an options list from our fields
      return this.fields
          .filter(f => f.sortable)
          .map(f => ({
            text: f.label,
            value: f.key,
          }))
    },
    cariBilgi() {
      if (store.getters.CARI_EKSTRE.cariBilgi) {
        return store.getters.CARI_EKSTRE.cariBilgi
      }
      return 'Veri Bulunamadı'
    },
    cariEkstre() {
      if (store.getters.CARI_EKSTRE) {
        return _.orderBy(store.getters.CARI_EKSTRE.ekstre, 'kayitTarih', 'desc')
      }
      return 'Veri Bulunamadı'
    },
  },
  watch: {
    start(newVal) {
      this.$store
          .dispatch('cariEkstre', {
            cariKartID: this.id,
            baslangic: newVal,
            bitis: this.end,
          })
          .then((res, position = 'bottom-right') => {
            // this.toplamKayit = res.data.hesapOzeti.toplamKayit
            this.mevcutKayit = Object.keys(res.ekstre).length
            const mesaj = this.$store.getters.notificationSettings(res)
            if (mesaj) {
              this.$toast(
                  {
                    component: ToastificationContent,
                    props: {
                      title: 'Başarılı',
                      text: mesaj,
                      icon: 'ThumbsUpIcon',
                      variant: 'success',
                    },
                  },
                  { position },
              )
            }
          })
          .catch(err => {
            const mesaj = this.$store.getters.notificationSettings(err)
            if (mesaj) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Hata!',
                  text: mesaj,
                  icon: 'ThumbsDownIcon',
                  variant: 'danger',
                },
              })
            }
          })
    },
    end(newVal) {
      this.$store
          .dispatch('cariEkstre', {
            cariKartID: this.id,
            baslangic: this.start,
            bitis: newVal,
          })
          .then((res, position = 'bottom-right') => {
            // this.toplamKayit = res.data.hesapOzeti.toplamKayit
            this.mevcutKayit = Object.keys(res.ekstre).length
            const mesaj = this.$store.getters.notificationSettings(res)
            if (mesaj) {
              this.$toast(
                  {
                    component: ToastificationContent,
                    props: {
                      title: 'Başarılı',
                      text: mesaj,
                      icon: 'ThumbsUpIcon',
                      variant: 'success',
                    },
                  },
                  { position },
              )
            }
          })
          .catch(err => {
            const mesaj = this.$store.getters.notificationSettings(err)
            if (mesaj) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Hata!',
                  text: mesaj,
                  icon: 'ThumbsDownIcon',
                  variant: 'danger',
                },
              })
            }
          })
    },
    '$route.params.search': function (id) {
      this.$store
          .dispatch('cariEkstre', {
            cariKartID: id,
          })
          .then((res, position = 'bottom-right') => {
            this.toplamKayit = res.data.hesapOzeti.toplamKayit
            this.mevcutKayit = Object.keys(res.ekstre).length
            const mesaj = this.$store.getters.notificationSettings(res)
            if (mesaj) {
              this.$toast(
                  {
                    component: ToastificationContent,
                    props: {
                      title: 'Başarılı',
                      text: mesaj,
                      icon: 'ThumbsUpIcon',
                      variant: 'success',
                    },
                  },
                  { position },
              )
            }
          })
          .catch(err => {
            const mesaj = this.$store.getters.notificationSettings(err)
            if (mesaj) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Hata!',
                  text: mesaj,
                  icon: 'ThumbsDownIcon',
                  variant: 'danger',
                },
              })
            }
          })
    },
  },
  methods: {
    print() {
      window.print()
    },
    PerPageChange(newVal) {
      const veri = this.currentPage * newVal - newVal
      this.$refs['cari-tab'].showLoading = true
      this.$store
          .dispatch('cariEkstre', {
            uzunluk: newVal,
            baslangic: veri,
            searchKey: this.Filter,
            cariKartID: this.id,
          })
          .then(res => {
            this.$refs['cari-tab'].showLoading = false
            this.toplamKayit = res.data.hesapOzeti.toplamKayit
            this.mevcutKayit = res.ekstre.length
          })
          .catch(err => {
            const mesaj = this.$store.getters.notificationSettings(err)
            if (mesaj) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Hata!',
                  text: mesaj,
                  icon: 'ThumbsDownIcon',
                  variant: 'danger',
                },
              })
            }
          })
    },
    pageChange(newVal) {
      const veri = newVal * this.afPerPage - this.afPerPage
      this.$refs['cari-tab'].showLoading = true
      this.$store
          .dispatch('cariDetayIslemGecmisi', {
            uzunluk: this.afPerPage,
            baslangic: veri,
            searchKey: this.afFilter,
            cariKartID: this.id,
          })
          .then(res => {
            this.$refs['cari-tab'].showLoading = false
            this.afToplamKayit = res.data.cariEkstre.toplamKayit
            this.afMevcutKayit = res.data.cariEkstre.data.length
          })
          .catch(err => {
            const mesaj = this.$store.getters.notificationSettings(err)
            if (mesaj) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Hata!',
                  text: mesaj,
                  icon: 'ThumbsDownIcon',
                  variant: 'danger',
                },
              })
            }
          })
    },
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
    tahsilatDelete(kasaID) {
      this.$refs['tahsilat-card'].showLoading = true
      this.$store
          .dispatch('deleteTahsilat', {
            kasaID,
            cariKartID: this.id,
          })
          .then((res, position = 'bottom-right') => {
            this.$refs['tahsilat-card'].showLoading = false
            if (res.result.status == 200) {
              this.$store
                  .dispatch('cariDetay', {
                    cariKartID: this.id,
                  })
              const mesaj = this.$store.getters.notificationSettings(res)
              if (mesaj) {
                this.$toast(
                    {
                      component: ToastificationContent,
                      props: {
                        title: 'Başarılı',
                        text: mesaj,
                        icon: 'ThumbsUpIcon',
                        variant: 'success',
                      },
                    },
                    { position },
                )
              }
            } else {
              const mesaj = this.$store.getters.notificationSettings(res)
              if (mesaj) {
                this.$toast(
                    {
                      component: ToastificationContent,
                      props: {
                        title: 'Hata!',
                        text: mesaj,
                        icon: 'ThumbsUpIcon',
                        variant: 'Warning',
                      },
                    },
                    { position },
                )
              }
            }
          })
          .catch(err => {
            this.$refs['tahsilat-card'].showLoading = false
            const mesaj = this.$store.getters.notificationSettings(err)
            if (mesaj) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Hata!',
                  text: mesaj,
                  icon: 'ThumbsDownIcon',
                  variant: 'danger',
                },
              })
            }
          })
    },
    refreshStop(cardName) {
      setTimeout(() => {
        this.$refs[cardName].showLoading = false
      }, 3000)
    },
  },
  mounted() {
    this.windowWidth = window.innerWidth
    this.windowHeight = window.innerHeight
    window.addEventListener('resize', this.handleResize)
  },
  created() {
    this.$store
        .dispatch('cariEkstre', {
          cariKartID: this.id,
        })
        .then((res, position = 'bottom-right') => {
          // this.toplamKayit = res.data.hesapOzeti.toplamKayit
          this.mevcutKayit = Object.keys(res.ekstre).length
          const mesaj = this.$store.getters.notificationSettings(res)
          if (mesaj) {
            this.$toast(
                {
                  component: ToastificationContent,
                  props: {
                    title: 'Başarılı',
                    text: mesaj,
                    icon: 'ThumbsUpIcon',
                    variant: 'success',
                  },
                },
                { position },
            )
          }
        })
        .catch(err => {
          const mesaj = this.$store.getters.notificationSettings(err)
          if (mesaj) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Hata!',
                text: mesaj,
                icon: 'ThumbsDownIcon',
                variant: 'danger',
              },
            })
          }
        })
  },
  beforeDestroy() {
    this.$store.commit('CARI_EKSTRE', 'remove')
  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
}
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
<style>
#cari-ekstre .card-header {
  display: none !important;
}

#cari-detay-page #cari-info-card .card {
  margin-bottom: 2% !important;
}

#cari-detay-page .card .card-header .heading-elements {
  display: none !important;
}

#cari-detay-page .cari-avatar {
  width: 94px !important;
  height: 94px !important;
}

#cari-detay-page .cari-adres {
  font-size: 11px;
}
</style>
<style scoped>
.table th, .table td {
  padding: 0.12rem 2rem !important;
}

.table:not(.table-dark):not(.table-light) thead:not(.thead-dark) th, [dir] .table:not(.table-dark):not(.table-light) tfoot:not(.thead-dark) th {
  background-color: white !important;
  border-top: none !important;
  border-bottom: none !important;
}

.input-group {
  box-shadow: none !important;
}

.date-border-color {
  border-color: #bdb5d7 !important
}

.firma-unvan {
  color: #C92C6D !important;
  font-weight: 900 !important;
}

.search-bar {
  width: 20% !important;
}

.input-group > .input-group-prepend > .btn {
  border-color: #c2bcd5 !important;
}

@media screen and (max-width: 1440px) {
  #cari-detay-page {
    /*display: none !important;*/
  }
}

#time-line-hr {
  height: 2px;
  width: 90%;
  position: absolute;
  margin-top: 1.9%;
}

.pull-up {
  transition: 200ms;
}

.timeline-hr {
  width: 60% !important;
  height: 2px !important;
  opacity: 0.8;
  transform: translateY(-25px);
}

.timeline-row {
  transform: translateY(-16px) !important;
}
</style>
